import { CountryCode } from '@lib/kyc/constants/countriesMap';

/** Two-letter country codes as described in ISO3166 */
const ISO3166Mappings: Record<CountryCode, string> = {
  [CountryCode.AFG]: 'AF', // Afghanistan
  [CountryCode.ALA]: 'AX', // Åland Islands
  [CountryCode.ALB]: 'AL', // Albania
  [CountryCode.DZA]: 'DZ', // Algeria
  [CountryCode.ASM]: 'AS', // American Samoa
  [CountryCode.AND]: 'AD', // Andorra
  [CountryCode.AGO]: 'AO', // Angola
  [CountryCode.AIA]: 'AI', // Anguilla
  [CountryCode.ATA]: 'AQ', // Antarctica
  [CountryCode.ATG]: 'AG', // Antigua and Barbuda
  [CountryCode.ARG]: 'AR', // Argentina
  [CountryCode.ARM]: 'AM', // Armenia
  [CountryCode.ABW]: 'AW', // Aruba
  [CountryCode.AUS]: 'AU', // Australia
  [CountryCode.AUT]: 'AT', // Austria
  [CountryCode.AZE]: 'AZ', // Azerbaijan
  [CountryCode.BHS]: 'BS', // Bahamas
  [CountryCode.BHR]: 'BH', // Bahrain
  [CountryCode.BGD]: 'BD', // Bangladesh
  [CountryCode.BRB]: 'BB', // Barbados
  [CountryCode.BLR]: 'BY', // Belarus
  [CountryCode.BEL]: 'BE', // Belgium
  [CountryCode.BLZ]: 'BZ', // Belize
  [CountryCode.BEN]: 'BJ', // Benin
  [CountryCode.BMU]: 'BM', // Bermuda
  [CountryCode.BTN]: 'BT', // Bhutan
  [CountryCode.BOL]: 'BO', // Bolivia (Plurinational State of)
  [CountryCode.BES]: 'BQ', // Bonaire, Sint Eustatius and Saba
  [CountryCode.BIH]: 'BA', // Bosnia and Herzegovina
  [CountryCode.BWA]: 'BW', // Botswana
  [CountryCode.BVT]: 'BV', // Bouvet Island
  [CountryCode.BRA]: 'BR', // Brazil
  [CountryCode.IOT]: 'IO', // British Indian Ocean Territory
  [CountryCode.BRN]: 'BN', // Brunei Darussalam
  [CountryCode.BGR]: 'BG', // Bulgaria
  [CountryCode.BFA]: 'BF', // Burkina Faso
  [CountryCode.BDI]: 'BI', // Burundi
  [CountryCode.CPV]: 'CV', // Cabo Verde
  [CountryCode.KHM]: 'KH', // Cambodia
  [CountryCode.CMR]: 'CM', // Cameroon
  [CountryCode.CAN]: 'CA', // Canada
  [CountryCode.CYM]: 'KY', // Cayman Islands
  [CountryCode.CAF]: 'CF', // Central African Republic
  [CountryCode.TCD]: 'TD', // Chad
  [CountryCode.CHL]: 'CL', // Chile
  [CountryCode.CHN]: 'CN', // China
  [CountryCode.CXR]: 'CX', // Christmas Island
  [CountryCode.CCK]: 'CC', // Cocos (Keeling) Islands
  [CountryCode.COL]: 'CO', // Colombia
  [CountryCode.COM]: 'KM', // Comoros
  [CountryCode.COG]: 'CG', // Congo
  [CountryCode.COD]: 'CD', // Congo (Democratic Republic of the)
  [CountryCode.COK]: 'CK', // Cook Islands
  [CountryCode.CRI]: 'CR', // Costa Rica
  [CountryCode.CIV]: 'CI', // Côte d'Ivoire
  [CountryCode.HRV]: 'HR', // Croatia
  [CountryCode.CUB]: 'CU', // Cuba
  [CountryCode.CUW]: 'CW', // Curaçao
  [CountryCode.CYP]: 'CY', // Cyprus
  [CountryCode.CZE]: 'CZ', // Czechia
  [CountryCode.DNK]: 'DK', // Denmark
  [CountryCode.DJI]: 'DJ', // Djibouti
  [CountryCode.DMA]: 'DM', // Dominica
  [CountryCode.DOM]: 'DO', // Dominican Republic
  [CountryCode.ECU]: 'EC', // Ecuador
  [CountryCode.EGY]: 'EG', // Egypt
  [CountryCode.SLV]: 'SV', // El Salvador
  [CountryCode.GNQ]: 'GQ', // Equatorial Guinea
  [CountryCode.ERI]: 'ER', // Eritrea
  [CountryCode.EST]: 'EE', // Estonia
  [CountryCode.SWZ]: 'SZ', // Eswatini
  [CountryCode.ETH]: 'ET', // Ethiopia
  [CountryCode.FLK]: 'FK', // Falkland Islands (Malvinas)
  [CountryCode.FRO]: 'FO', // Faroe Islands
  [CountryCode.FJI]: 'FJ', // Fiji
  [CountryCode.FIN]: 'FI', // Finland
  [CountryCode.FRA]: 'FR', // France
  [CountryCode.GUF]: 'GF', // French Guiana
  [CountryCode.PYF]: 'PF', // French Polynesia
  [CountryCode.ATF]: 'TF', // French Southern Territories
  [CountryCode.GAB]: 'GA', // Gabon
  [CountryCode.GMB]: 'GM', // Gambia
  [CountryCode.GEO]: 'GE', // Georgia
  [CountryCode.DEU]: 'DE', // Germany
  [CountryCode.GHA]: 'GH', // Ghana
  [CountryCode.GIB]: 'GI', // Gibraltar
  [CountryCode.GRC]: 'GR', // Greece
  [CountryCode.GRL]: 'GL', // Greenland
  [CountryCode.GRD]: 'GD', // Grenada
  [CountryCode.GLP]: 'GP', // Guadeloupe
  [CountryCode.GUM]: 'GU', // Guam
  [CountryCode.GTM]: 'GT', // Guatemala
  [CountryCode.GGY]: 'GG', // Guernsey
  [CountryCode.GIN]: 'GN', // Guinea
  [CountryCode.GNB]: 'GW', // Guinea-Bissau
  [CountryCode.GUY]: 'GY', // Guyana
  [CountryCode.HTI]: 'HT', // Haiti
  [CountryCode.HMD]: 'HM', // Heard Island and McDonald Islands
  [CountryCode.VAT]: 'VA', // Holy See
  [CountryCode.HND]: 'HN', // Honduras
  [CountryCode.HKG]: 'HK', // Hong Kong
  [CountryCode.HUN]: 'HU', // Hungary
  [CountryCode.ISL]: 'IS', // Iceland
  [CountryCode.IND]: 'IN', // India
  [CountryCode.IDN]: 'ID', // Indonesia
  [CountryCode.IRN]: 'IR', // Iran (Islamic Republic of)
  [CountryCode.IRQ]: 'IQ', // Iraq
  [CountryCode.IRL]: 'IE', // Ireland
  [CountryCode.IMN]: 'IM', // Isle of Man
  [CountryCode.ISR]: 'IL', // Israel
  [CountryCode.ITA]: 'IT', // Italy
  [CountryCode.JAM]: 'JM', // Jamaica
  [CountryCode.JPN]: 'JP', // Japan
  [CountryCode.JEY]: 'JE', // Jersey
  [CountryCode.JOR]: 'JO', // Jordan
  [CountryCode.KAZ]: 'KZ', // Kazakhstan
  [CountryCode.KEN]: 'KE', // Kenya
  [CountryCode.KIR]: 'KI', // Kiribati
  [CountryCode.PRK]: 'KP', // Korea (Democratic Peopl's Republic of)
  [CountryCode.KOR]: 'KE', // Korea (Republic of)
  [CountryCode.KWT]: 'KW', // Kuwait
  [CountryCode.KGZ]: 'KG', // Kyrgyzstan
  [CountryCode.LAO]: 'LA', // Lao Peopl's Democratic Republic
  [CountryCode.LVA]: 'LV', // Latvia
  [CountryCode.LBN]: 'LB', // Lebanon
  [CountryCode.LSO]: 'LS', // Lesotho
  [CountryCode.LBR]: 'LR', // Liberia
  [CountryCode.LBY]: 'LY', // Libya
  [CountryCode.LIE]: 'LI', // Liechtenstein
  [CountryCode.LTU]: 'LT', // Lithuania
  [CountryCode.LUX]: 'LU', // Luxembourg
  [CountryCode.MAC]: 'MO', // Macao
  [CountryCode.MKD]: 'MK', // Macedonia (the former Yugoslav Republic of)
  [CountryCode.MDG]: 'MG', // Madagascar
  [CountryCode.MWI]: 'MW', // Malawi
  [CountryCode.MYS]: 'MY', // Malaysia
  [CountryCode.MDV]: 'MV', // Maldives
  [CountryCode.MLI]: 'ML', // Mali
  [CountryCode.MLT]: 'MT', // Malta
  [CountryCode.MHL]: 'MH', // Marshall Islands
  [CountryCode.MTQ]: 'MQ', // Martinique
  [CountryCode.MRT]: 'MR', // Mauritania
  [CountryCode.MUS]: 'MU', // Mauritius
  [CountryCode.MYT]: 'YT', // Mayotte
  [CountryCode.MEX]: 'MX', // Mexico
  [CountryCode.FSM]: 'FM', // Micronesia (Federated States of)
  [CountryCode.MDA]: 'MD', // Moldova (Republic of)
  [CountryCode.MCO]: 'MC', // Monaco
  [CountryCode.MNG]: 'MN', // Mongolia
  [CountryCode.MNE]: 'ME', // Montenegro
  [CountryCode.MSR]: 'MS', // Montserrat
  [CountryCode.MAR]: 'MA', // Morocco
  [CountryCode.MOZ]: 'MZ', // Mozambique
  [CountryCode.MMR]: 'MM', // Myanmar
  [CountryCode.NAM]: 'NA', // Namibia
  [CountryCode.NRU]: 'NR', // Nauru
  [CountryCode.NPL]: 'NP', // Nepal
  [CountryCode.NLD]: 'NL', // Netherlands
  [CountryCode.NCL]: 'NC', // New Caledonia
  [CountryCode.NZL]: 'NZ', // New Zealand
  [CountryCode.NIC]: 'NI', // Nicaragua
  [CountryCode.NER]: 'NE', // Niger
  [CountryCode.NGA]: 'NG', // Nigeria
  [CountryCode.NIU]: 'NU', // Niue
  [CountryCode.NFK]: 'NF', // Norfolk Island
  [CountryCode.MNP]: 'MP', // Northern Mariana Islands
  [CountryCode.NOR]: 'NO', // Norway
  [CountryCode.OMN]: 'OM', // Oman
  [CountryCode.PAK]: 'PA', // Pakistan
  [CountryCode.PLW]: 'PW', // Palau
  [CountryCode.PSE]: 'PS', // Palestine, State of
  [CountryCode.PAN]: 'PA', // Panama
  [CountryCode.PNG]: 'PG', // Papua New Guinea
  [CountryCode.PRY]: 'PY', // Paraguay
  [CountryCode.PER]: 'PE', // Peru
  [CountryCode.PHL]: 'PH', // Philippines
  [CountryCode.PCN]: 'PN', // Pitcairn
  [CountryCode.POL]: 'PL', // Poland
  [CountryCode.PRT]: 'PT', // Portugal
  [CountryCode.PRI]: 'PR', // Puerto Rico
  [CountryCode.QAT]: 'QA', // Qatar
  [CountryCode.REU]: 'RE', // Réunion
  [CountryCode.ROU]: 'RO', // Romania
  [CountryCode.RUS]: 'RU', // Russian Federation
  [CountryCode.RWA]: 'RW', // Rwanda
  [CountryCode.BLM]: 'BL', // Saint Barthélemy
  [CountryCode.SHN]: 'SH', // Saint Helena, Ascension and Tristan da Cunha
  [CountryCode.KNA]: 'KN', // Saint Kitts and Nevis
  [CountryCode.LCA]: 'LC', // Saint Lucia
  [CountryCode.MAF]: 'MF', // Saint Martin (French part)
  [CountryCode.SPM]: 'PM', // Saint Pierre and Miquelon
  [CountryCode.VCT]: 'VC', // Saint Vincent and the Grenadines
  [CountryCode.WSM]: 'WS', // Samoa
  [CountryCode.SMR]: 'SM', // San Marino
  [CountryCode.STP]: 'ST', // Sao Tome and Principe
  [CountryCode.SAU]: 'SA', // Saudi Arabia
  [CountryCode.SEN]: 'SN', // Senegal
  [CountryCode.SRB]: 'RS', // Serbia
  [CountryCode.SYC]: 'SC', // Seychelles
  [CountryCode.SLE]: 'SL', // Sierra Leone
  [CountryCode.SGP]: 'SG', // Singapore
  [CountryCode.SXM]: 'SX', // Sint Maarten (Dutch part)
  [CountryCode.SVK]: 'SK', // Slovakia
  [CountryCode.SVN]: 'SI', // Slovenia
  [CountryCode.SLB]: 'SB', // Solomon Islands
  [CountryCode.SOM]: 'SO', // Somalia
  [CountryCode.ZAF]: 'ZA', // South Africa
  [CountryCode.SGS]: 'GS', // South Georgia and the South Sandwich Islands
  [CountryCode.SSD]: 'SS', // South Sudan
  [CountryCode.ESP]: 'ES', // Spain
  [CountryCode.LKA]: 'LK', // Sri Lanka
  [CountryCode.SDN]: 'SD', // Sudan
  [CountryCode.SUR]: 'SR', // Suriname
  [CountryCode.SJM]: 'SJ', // Svalbard and Jan Mayen
  [CountryCode.SWE]: 'SE', // Sweden
  [CountryCode.CHE]: 'CH', // Switzerland
  [CountryCode.SYR]: 'SY', // Syrian Arab Republic
  [CountryCode.TWN]: 'TW', // Taiwan, Province of China
  [CountryCode.TJK]: 'TJ', // Tajikistan
  [CountryCode.TZA]: 'TZ', // Tanzania, United Republic of
  [CountryCode.THA]: 'TH', // Thailand
  [CountryCode.TLS]: 'TL', // Timor-Leste
  [CountryCode.TGO]: 'TG', // Togo
  [CountryCode.TKL]: 'TK', // Tokelau
  [CountryCode.TON]: 'TO', // Tonga
  [CountryCode.TTO]: 'TT', // Trinidad and Tobago
  [CountryCode.TUN]: 'TN', // Tunisia
  [CountryCode.TUR]: 'TR', // Turkey
  [CountryCode.TKM]: 'TM', // Turkmenistan
  [CountryCode.TCA]: 'TC', // Turks and Caicos Islands
  [CountryCode.TUV]: 'TV', // Tuvalu
  [CountryCode.UGA]: 'UG', // Uganda
  [CountryCode.UKR]: 'UA', // Ukraine
  [CountryCode.ARE]: 'AE', // United Arab Emirates
  [CountryCode.GBR]: 'GB', // United Kingdom of Great Britain and Northern Ireland
  [CountryCode.USA]: 'US', // United States of America
  [CountryCode.UMI]: 'UM', // United States Minor Outlying Islands
  [CountryCode.URY]: 'UY', // Uruguay
  [CountryCode.UZB]: 'UZ', // Uzbekistan
  [CountryCode.VUT]: 'VU', // Vanuatu
  [CountryCode.VEN]: 'VE', // Venezuela (Bolivarian Republic of)
  [CountryCode.VNM]: 'VN', // Viet Nam
  [CountryCode.VGB]: 'VG', // Virgin Islands (British)
  [CountryCode.VIR]: 'VI', // Virgin Islands (U.S.)
  [CountryCode.WLF]: 'WF', // Wallis and Futuna
  [CountryCode.ESH]: 'EH', // Western Sahara
  [CountryCode.XKX]: 'XK', // Kosovo
  [CountryCode.YEM]: 'YE', // Yemen
  [CountryCode.ZMB]: 'ZM', // Zambia
  [CountryCode.ZWE]: 'ZW', // Zimbabwe
};

export default ISO3166Mappings;
