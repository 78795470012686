import { useRouter } from 'next/router';
import { BiCaretDown } from 'react-icons/bi';
import type { MouseEventHandler } from 'react';
import { useState } from 'react';
import Flag from '@components/forms/fields/Flag';
import Link from '@components/routing/Link';
import { CountryCode } from '@lib/kyc/constants/countriesMap';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const mappings: Record<string, { label: string; code: CountryCode }> = {
  en: { label: 'English', code: CountryCode.GBR },
  fr: { label: 'Français', code: CountryCode.FRA },
  tr: { label: 'Türkçe', code: CountryCode.TUR },
};

const LanguageSwitcher = () => {
  const router = useRouter();
  const [anchor, setAnchor] = useState<Element>();

  const handleOpen: MouseEventHandler<HTMLButtonElement> = ($e) => {
    setAnchor((prev) => (prev ? undefined : ($e.target as Element)));
  };

  const handleClose = () => setAnchor(undefined);

  return (
    <>
      <Button variant="text" size="large" onClick={handleOpen} className="p-0 min-w-0 leading-none text-white">
        <Flag code={mappings[router.locale ?? '']?.code ?? CountryCode.GBR} className="block" width={24} />{' '}
        <BiCaretDown />
      </Button>

      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={handleClose}>
        {Object.entries(mappings).map(([key, { label, code }]) => (
          <MenuItem key={key}>
            <Link href={router.pathname} locale={key} className="text-white no-underline">
              <Flag code={code} className="mr-2" width={24} />
              {label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
