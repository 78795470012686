import type { ComponentProps, VFC } from 'react';
import ISO3166Mappings from '@lib/kyc/constants/ISO3166Mappings';
import type { CountryCode } from '@lib/kyc/constants/countriesMap';
import countriesMap from '@lib/kyc/constants/countriesMap';

interface FlagProps extends Omit<ComponentProps<'img'>, 'src' | 'srcSet' | 'alt'> {
  code: CountryCode;
}

const resolutions = [20, 40, 80, 160, 320, 640, 1280, 2560];

const Flag: VFC<FlagProps> = ({ code, width: rawWidth = 20, ...props }) => {
  const width = typeof rawWidth === 'string' ? parseInt(rawWidth) : rawWidth;
  const country = countriesMap.get(code);
  const flag = ISO3166Mappings[code]?.toLowerCase();
  const res = resolutions.find((r) => r >= width) ?? resolutions[resolutions.length - 1];

  if (!country || !flag) return null;

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      loading="lazy"
      src={`https://flagcdn.com/w${res}/${flag}.png`}
      alt={`Flag of ${country.name}`}
      width={width}
      {...props}
    />
  );
};

export default Flag;
