export interface Country {
  name: string;
  countryCode: CountryCode;
  region: string;
  subRegion: string;
  locale: Locale;
}

export enum Locale {
  'bg' = 'bg',
  'cs' = 'cs',
  'da' = 'da',
  'de' = 'de',
  'el' = 'el',
  'en' = 'en',
  'en_GB' = 'en_GB',
  'es' = 'es',
  'es_MX' = 'es_MX',
  'et' = 'et',
  'fi' = 'fi',
  'fr' = 'fr',
  'hu' = 'hu',
  'it' = 'it',
  'ja' = 'ja',
  'ko' = 'ko',
  'lt' = 'lt',
  'nl' = 'nl',
  'no' = 'no',
  'pl' = 'pl',
  'pt' = 'pt',
  'pt_BR' = 'pt_BR',
  'ro' = 'ro',
  'ru' = 'ru',
  'sk' = 'sk',
  'sv' = 'sv',
  'tr' = 'tr',
  'vl' = 'vl',
  'zh_CN' = 'zh_CN',
  'zh_HK' = 'zh_HK',
}

export enum CountryCode {
  AFG = 'AFG', // Afghanistan
  ALA = 'ALA', // Åland Islands
  ALB = 'ALB', // Albania
  DZA = 'DZA', // Algeria
  ASM = 'ASM', // American Samoa
  AND = 'AND', // Andorra
  AGO = 'AGO', // Angola
  AIA = 'AIA', // Anguilla
  ATA = 'ATA', // Antarctica
  ATG = 'ATG', // Antigua and Barbuda
  ARG = 'ARG', // Argentina
  ARM = 'ARM', // Armenia
  ABW = 'ABW', // Aruba
  AUS = 'AUS', // Australia
  AUT = 'AUT', // Austria
  AZE = 'AZE', // Azerbaijan
  BHS = 'BHS', // Bahamas
  BHR = 'BHR', // Bahrain
  BGD = 'BGD', // Bangladesh
  BRB = 'BRB', // Barbados
  BLR = 'BLR', // Belarus
  BEL = 'BEL', // Belgium
  BLZ = 'BLZ', // Belize
  BEN = 'BEN', // Benin
  BMU = 'BMU', // Bermuda
  BTN = 'BTN', // Bhutan
  BOL = 'BOL', // Bolivia (Plurinational State of)
  BES = 'BES', // Bonaire, Sint Eustatius and Saba
  BIH = 'BIH', // Bosnia and Herzegovina
  BWA = 'BWA', // Botswana
  BVT = 'BVT', // Bouvet Island
  BRA = 'BRA', // Brazil
  IOT = 'IOT', // British Indian Ocean Territory
  BRN = 'BRN', // Brunei Darussalam
  BGR = 'BGR', // Bulgaria
  BFA = 'BFA', // Burkina Faso
  BDI = 'BDI', // Burundi
  CPV = 'CPV', // Cabo Verde
  KHM = 'KHM', // Cambodia
  CMR = 'CMR', // Cameroon
  CAN = 'CAN', // Canada
  CYM = 'CYM', // Cayman Islands
  CAF = 'CAF', // Central African Republic
  TCD = 'TCD', // Chad
  CHL = 'CHL', // Chile
  CHN = 'CHN', // China
  CXR = 'CXR', // Christmas Island
  CCK = 'CCK', // Cocos (Keeling) Islands
  COL = 'COL', // Colombia
  COM = 'COM', // Comoros
  COG = 'COG', // Congo
  COD = 'COD', // Congo (Democratic Republic of the)
  COK = 'COK', // Cook Islands
  CRI = 'CRI', // Costa Rica
  CIV = 'CIV', // Côte d'Ivoire
  HRV = 'HRV', // Croatia
  CUB = 'CUB', // Cuba
  CUW = 'CUW', // Curaçao
  CYP = 'CYP', // Cyprus
  CZE = 'CZE', // Czechia
  DNK = 'DNK', // Denmark
  DJI = 'DJI', // Djibouti
  DMA = 'DMA', // Dominica
  DOM = 'DOM', // Dominican Republic
  ECU = 'ECU', // Ecuador
  EGY = 'EGY', // Egypt
  SLV = 'SLV', // El Salvador
  GNQ = 'GNQ', // Equatorial Guinea
  ERI = 'ERI', // Eritrea
  EST = 'EST', // Estonia
  SWZ = 'SWZ', // Eswatini
  ETH = 'ETH', // Ethiopia
  FLK = 'FLK', // Falkland Islands (Malvinas)
  FRO = 'FRO', // Faroe Islands
  FJI = 'FJI', // Fiji
  FIN = 'FIN', // Finland
  FRA = 'FRA', // France
  GUF = 'GUF', // French Guiana
  PYF = 'PYF', // French Polynesia
  ATF = 'ATF', // French Southern Territories
  GAB = 'GAB', // Gabon
  GMB = 'GMB', // Gambia
  GEO = 'GEO', // Georgia
  DEU = 'DEU', // Germany
  GHA = 'GHA', // Ghana
  GIB = 'GIB', // Gibraltar
  GRC = 'GRC', // Greece
  GRL = 'GRL', // Greenland
  GRD = 'GRD', // Grenada
  GLP = 'GLP', // Guadeloupe
  GUM = 'GUM', // Guam
  GTM = 'GTM', // Guatemala
  GGY = 'GGY', // Guernsey
  GIN = 'GIN', // Guinea
  GNB = 'GNB', // Guinea-Bissau
  GUY = 'GUY', // Guyana
  HTI = 'HTI', // Haiti
  HMD = 'HMD', // Heard Island and McDonald Islands
  VAT = 'VAT', // Holy See
  HND = 'HND', // Honduras
  HKG = 'HKG', // Hong Kong
  HUN = 'HUN', // Hungary
  ISL = 'ISL', // Iceland
  IND = 'IND', // India
  IDN = 'IDN', // Indonesia
  IRN = 'IRN', // Iran (Islamic Republic of)
  IRQ = 'IRQ', // Iraq
  IRL = 'IRL', // Ireland
  IMN = 'IMN', // Isle of Man
  ISR = 'ISR', // Israel
  ITA = 'ITA', // Italy
  JAM = 'JAM', // Jamaica
  JPN = 'JPN', // Japan
  JEY = 'JEY', // Jersey
  JOR = 'JOR', // Jordan
  KAZ = 'KAZ', // Kazakhstan
  KEN = 'KEN', // Kenya
  KIR = 'KIR', // Kiribati
  PRK = 'PRK', // Korea (Democratic People's Republic of)
  KOR = 'KOR', // Korea (Republic of)
  KWT = 'KWT', // Kuwait
  KGZ = 'KGZ', // Kyrgyzstan
  LAO = 'LAO', // Lao People's Democratic Republic
  LVA = 'LVA', // Latvia
  LBN = 'LBN', // Lebanon
  LSO = 'LSO', // Lesotho
  LBR = 'LBR', // Liberia
  LBY = 'LBY', // Libya
  LIE = 'LIE', // Liechtenstein
  LTU = 'LTU', // Lithuania
  LUX = 'LUX', // Luxembourg
  MAC = 'MAC', // Macao
  MKD = 'MKD', // Macedonia (the former Yugoslav Republic of)
  MDG = 'MDG', // Madagascar
  MWI = 'MWI', // Malawi
  MYS = 'MYS', // Malaysia
  MDV = 'MDV', // Maldives
  MLI = 'MLI', // Mali
  MLT = 'MLT', // Malta
  MHL = 'MHL', // Marshall Islands
  MTQ = 'MTQ', // Martinique
  MRT = 'MRT', // Mauritania
  MUS = 'MUS', // Mauritius
  MYT = 'MYT', // Mayotte
  MEX = 'MEX', // Mexico
  FSM = 'FSM', // Micronesia (Federated States of)
  MDA = 'MDA', // Moldova (Republic of)
  MCO = 'MCO', // Monaco
  MNG = 'MNG', // Mongolia
  MNE = 'MNE', // Montenegro
  MSR = 'MSR', // Montserrat
  MAR = 'MAR', // Morocco
  MOZ = 'MOZ', // Mozambique
  MMR = 'MMR', // Myanmar
  NAM = 'NAM', // Namibia
  NRU = 'NRU', // Nauru
  NPL = 'NPL', // Nepal
  NLD = 'NLD', // Netherlands
  NCL = 'NCL', // New Caledonia
  NZL = 'NZL', // New Zealand
  NIC = 'NIC', // Nicaragua
  NER = 'NER', // Niger
  NGA = 'NGA', // Nigeria
  NIU = 'NIU', // Niue
  NFK = 'NFK', // Norfolk Island
  MNP = 'MNP', // Northern Mariana Islands
  NOR = 'NOR', // Norway
  OMN = 'OMN', // Oman
  PAK = 'PAK', // Pakistan
  PLW = 'PLW', // Palau
  PSE = 'PSE', // Palestine, State of
  PAN = 'PAN', // Panama
  PNG = 'PNG', // Papua New Guinea
  PRY = 'PRY', // Paraguay
  PER = 'PER', // Peru
  PHL = 'PHL', // Philippines
  PCN = 'PCN', // Pitcairn
  POL = 'POL', // Poland
  PRT = 'PRT', // Portugal
  PRI = 'PRI', // Puerto Rico
  QAT = 'QAT', // Qatar
  REU = 'REU', // Réunion
  ROU = 'ROU', // Romania
  RUS = 'RUS', // Russian Federation
  RWA = 'RWA', // Rwanda
  BLM = 'BLM', // Saint Barthélemy
  SHN = 'SHN', // Saint Helena, Ascension and Tristan da Cunha
  KNA = 'KNA', // Saint Kitts and Nevis
  LCA = 'LCA', // Saint Lucia
  MAF = 'MAF', // Saint Martin (French part)
  SPM = 'SPM', // Saint Pierre and Miquelon
  VCT = 'VCT', // Saint Vincent and the Grenadines
  WSM = 'WSM', // Samoa
  SMR = 'SMR', // San Marino
  STP = 'STP', // Sao Tome and Principe
  SAU = 'SAU', // Saudi Arabia
  SEN = 'SEN', // Senegal
  SRB = 'SRB', // Serbia
  SYC = 'SYC', // Seychelles
  SLE = 'SLE', // Sierra Leone
  SGP = 'SGP', // Singapore
  SXM = 'SXM', // Sint Maarten (Dutch part)
  SVK = 'SVK', // Slovakia
  SVN = 'SVN', // Slovenia
  SLB = 'SLB', // Solomon Islands
  SOM = 'SOM', // Somalia
  ZAF = 'ZAF', // South Africa
  SGS = 'SGS', // South Georgia and the South Sandwich Islands
  SSD = 'SSD', // South Sudan
  ESP = 'ESP', // Spain
  LKA = 'LKA', // Sri Lanka
  SDN = 'SDN', // Sudan
  SUR = 'SUR', // Suriname
  SJM = 'SJM', // Svalbard and Jan Mayen
  SWE = 'SWE', // Sweden
  CHE = 'CHE', // Switzerland
  SYR = 'SYR', // Syrian Arab Republic
  TWN = 'TWN', // Taiwan, Province of China
  TJK = 'TJK', // Tajikistan
  TZA = 'TZA', // Tanzania, United Republic of
  THA = 'THA', // Thailand
  TLS = 'TLS', // Timor-Leste
  TGO = 'TGO', // Togo
  TKL = 'TKL', // Tokelau
  TON = 'TON', // Tonga
  TTO = 'TTO', // Trinidad and Tobago
  TUN = 'TUN', // Tunisia
  TUR = 'TUR', // Turkey
  TKM = 'TKM', // Turkmenistan
  TCA = 'TCA', // Turks and Caicos Islands
  TUV = 'TUV', // Tuvalu
  UGA = 'UGA', // Uganda
  UKR = 'UKR', // Ukraine
  ARE = 'ARE', // United Arab Emirates
  GBR = 'GBR', // United Kingdom of Great Britain and Northern Ireland
  USA = 'USA', // United States of America
  UMI = 'UMI', // United States Minor Outlying Islands
  URY = 'URY', // Uruguay
  UZB = 'UZB', // Uzbekistan
  VUT = 'VUT', // Vanuatu
  VEN = 'VEN', // Venezuela (Bolivarian Republic of)
  VNM = 'VNM', // Viet Nam
  VGB = 'VGB', // Virgin Islands (British)
  VIR = 'VIR', // Virgin Islands (U.S.)
  WLF = 'WLF', // Wallis and Futuna
  ESH = 'ESH', // Western Sahara
  XKX = 'XKX', // Kosovo
  YEM = 'YEM', // Yemen
  ZMB = 'ZMB', // Zambia
  ZWE = 'ZWE', // Zimbabwe
}

const countries: Country[] = [
  {
    name: 'Afghanistan',
    countryCode: CountryCode.AFG,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Åland Islands',
    countryCode: CountryCode.ALA,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Albania',
    countryCode: CountryCode.ALB,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Algeria',
    countryCode: CountryCode.DZA,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'American Samoa',
    countryCode: CountryCode.ASM,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Andorra',
    countryCode: CountryCode.AND,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Angola',
    countryCode: CountryCode.AGO,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Anguilla',
    countryCode: CountryCode.AIA,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Antarctica',
    countryCode: CountryCode.ATA,
    region: '',
    subRegion: '',
    locale: Locale.en,
  },
  {
    name: 'Antigua and Barbuda',
    countryCode: CountryCode.ATG,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Argentina',
    countryCode: CountryCode.ARG,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Armenia',
    countryCode: CountryCode.ARM,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Aruba',
    countryCode: CountryCode.ABW,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Australia',
    countryCode: CountryCode.AUS,
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    locale: Locale.en,
  },
  {
    name: 'Austria',
    countryCode: CountryCode.AUT,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.en,
  },
  {
    name: 'Azerbaijan',
    countryCode: CountryCode.AZE,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Bahamas',
    countryCode: CountryCode.BHS,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Bahrain',
    countryCode: CountryCode.BHR,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Bangladesh',
    countryCode: CountryCode.BGD,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Barbados',
    countryCode: CountryCode.BRB,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Belarus',
    countryCode: CountryCode.BLR,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.en,
  },
  {
    name: 'Belgium',
    countryCode: CountryCode.BEL,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.en,
  },
  {
    name: 'Belize',
    countryCode: CountryCode.BLZ,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Benin',
    countryCode: CountryCode.BEN,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Bermuda',
    countryCode: CountryCode.BMU,
    region: 'Americas',
    subRegion: 'Northern America',
    locale: Locale.en,
  },
  {
    name: 'Bhutan',
    countryCode: CountryCode.BTN,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Bolivia (Plurinational State of)',
    countryCode: CountryCode.BOL,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    countryCode: CountryCode.BES,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Bosnia and Herzegovina',
    countryCode: CountryCode.BIH,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Botswana',
    countryCode: CountryCode.BWA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Bouvet Island',
    countryCode: CountryCode.BVT,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Brazil',
    countryCode: CountryCode.BRA,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.pt_BR,
  },
  {
    name: 'British Indian Ocean Territory',
    countryCode: CountryCode.IOT,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Brunei Darussalam',
    countryCode: CountryCode.BRN,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Bulgaria',
    countryCode: CountryCode.BGR,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.bg,
  },
  {
    name: 'Burkina Faso',
    countryCode: CountryCode.BFA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Burundi',
    countryCode: CountryCode.BDI,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Cabo Verde',
    countryCode: CountryCode.CPV,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Cambodia',
    countryCode: CountryCode.KHM,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Cameroon',
    countryCode: CountryCode.CMR,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Canada',
    countryCode: CountryCode.CAN,
    region: 'Americas',
    subRegion: 'Northern America',
    locale: Locale.en,
  },
  {
    name: 'Cayman Islands',
    countryCode: CountryCode.CYM,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Central African Republic',
    countryCode: CountryCode.CAF,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Chad',
    countryCode: CountryCode.TCD,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Chile',
    countryCode: CountryCode.CHL,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'China',
    countryCode: CountryCode.CHN,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.zh_HK,
  },
  {
    name: 'Christmas Island',
    countryCode: CountryCode.CXR,
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    locale: Locale.en,
  },
  {
    name: 'Cocos (Keeling) Islands',
    countryCode: CountryCode.CCK,
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    locale: Locale.en,
  },
  {
    name: 'Colombia',
    countryCode: CountryCode.COL,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Comoros',
    countryCode: CountryCode.COM,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Congo',
    countryCode: CountryCode.COG,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Congo (Democratic Republic of the)',
    countryCode: CountryCode.COD,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Cook Islands',
    countryCode: CountryCode.COK,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Costa Rica',
    countryCode: CountryCode.CRI,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: "Côte d'Ivoire",
    countryCode: CountryCode.CIV,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Croatia',
    countryCode: CountryCode.HRV,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Cuba',
    countryCode: CountryCode.CUB,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Curaçao',
    countryCode: CountryCode.CUW,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Cyprus',
    countryCode: CountryCode.CYP,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Czechia',
    countryCode: CountryCode.CZE,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.cs,
  },
  {
    name: 'Denmark',
    countryCode: CountryCode.DNK,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.da,
  },
  {
    name: 'Djibouti',
    countryCode: CountryCode.DJI,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Dominica',
    countryCode: CountryCode.DMA,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Dominican Republic',
    countryCode: CountryCode.DOM,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Ecuador',
    countryCode: CountryCode.ECU,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Egypt',
    countryCode: CountryCode.EGY,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'El Salvador',
    countryCode: CountryCode.SLV,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Equatorial Guinea',
    countryCode: CountryCode.GNQ,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Eritrea',
    countryCode: CountryCode.ERI,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Estonia',
    countryCode: CountryCode.EST,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.et,
  },
  {
    name: 'Eswatini',
    countryCode: CountryCode.SWZ,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Ethiopia',
    countryCode: CountryCode.ETH,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    countryCode: CountryCode.FLK,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Faroe Islands',
    countryCode: CountryCode.FRO,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Fiji',
    countryCode: CountryCode.FJI,
    region: 'Oceania',
    subRegion: 'Melanesia',
    locale: Locale.en,
  },
  {
    name: 'Finland',
    countryCode: CountryCode.FIN,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.fi,
  },
  {
    name: 'France',
    countryCode: CountryCode.FRA,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.fr,
  },
  {
    name: 'French Guiana',
    countryCode: CountryCode.GUF,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'French Polynesia',
    countryCode: CountryCode.PYF,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'French Southern Territories',
    countryCode: CountryCode.ATF,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Gabon',
    countryCode: CountryCode.GAB,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Gambia',
    countryCode: CountryCode.GMB,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Georgia',
    countryCode: CountryCode.GEO,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Germany',
    countryCode: CountryCode.DEU,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.de,
  },
  {
    name: 'Ghana',
    countryCode: CountryCode.GHA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Gibraltar',
    countryCode: CountryCode.GIB,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Greece',
    countryCode: CountryCode.GRC,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.el,
  },
  {
    name: 'Greenland',
    countryCode: CountryCode.GRL,
    region: 'Americas',
    subRegion: 'Northern America',
    locale: Locale.en,
  },
  {
    name: 'Grenada',
    countryCode: CountryCode.GRD,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Guadeloupe',
    countryCode: CountryCode.GLP,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Guam',
    countryCode: CountryCode.GUM,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Guatemala',
    countryCode: CountryCode.GTM,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Guernsey',
    countryCode: CountryCode.GGY,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Guinea',
    countryCode: CountryCode.GIN,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Guinea-Bissau',
    countryCode: CountryCode.GNB,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Guyana',
    countryCode: CountryCode.GUY,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Haiti',
    countryCode: CountryCode.HTI,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Heard Island and McDonald Islands',
    countryCode: CountryCode.HMD,
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    locale: Locale.en,
  },
  {
    name: 'Holy See',
    countryCode: CountryCode.VAT,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Honduras',
    countryCode: CountryCode.HND,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Hong Kong',
    countryCode: CountryCode.HKG,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Hungary',
    countryCode: CountryCode.HUN,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.hu,
  },
  {
    name: 'Iceland',
    countryCode: CountryCode.ISL,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'India',
    countryCode: CountryCode.IND,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Indonesia',
    countryCode: CountryCode.IDN,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Iran (Islamic Republic of)',
    countryCode: CountryCode.IRN,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Iraq',
    countryCode: CountryCode.IRQ,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Ireland',
    countryCode: CountryCode.IRL,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Isle of Man',
    countryCode: CountryCode.IMN,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Israel',
    countryCode: CountryCode.ISR,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Italy',
    countryCode: CountryCode.ITA,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.it,
  },
  {
    name: 'Jamaica',
    countryCode: CountryCode.JAM,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Japan',
    countryCode: CountryCode.JPN,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.ja,
  },
  {
    name: 'Jersey',
    countryCode: CountryCode.JEY,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Jordan',
    countryCode: CountryCode.JOR,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Kazakhstan',
    countryCode: CountryCode.KAZ,
    region: 'Asia',
    subRegion: 'Central Asia',
    locale: Locale.en,
  },
  {
    name: 'Kenya',
    countryCode: CountryCode.KEN,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Kiribati',
    countryCode: CountryCode.KIR,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: "Korea (Democratic People's Republic of)",
    countryCode: CountryCode.PRK,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.ko,
  },
  {
    name: 'Korea (Republic of)',
    countryCode: CountryCode.KOR,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.ko,
  },
  {
    name: 'Kosovo',
    countryCode: CountryCode.XKX,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Kuwait',
    countryCode: CountryCode.KWT,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Kyrgyzstan',
    countryCode: CountryCode.KGZ,
    region: 'Asia',
    subRegion: 'Central Asia',
    locale: Locale.en,
  },
  {
    name: "Lao People's Democratic Republic",
    countryCode: CountryCode.LAO,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Latvia',
    countryCode: CountryCode.LVA,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Lebanon',
    countryCode: CountryCode.LBN,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Lesotho',
    countryCode: CountryCode.LSO,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Liberia',
    countryCode: CountryCode.LBR,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Libya',
    countryCode: CountryCode.LBY,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'Liechtenstein',
    countryCode: CountryCode.LIE,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.en,
  },
  {
    name: 'Lithuania',
    countryCode: CountryCode.LTU,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.lt,
  },
  {
    name: 'Luxembourg',
    countryCode: CountryCode.LUX,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.en,
  },
  {
    name: 'Macao',
    countryCode: CountryCode.MAC,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    countryCode: CountryCode.MKD,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Madagascar',
    countryCode: CountryCode.MDG,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Malawi',
    countryCode: CountryCode.MWI,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Malaysia',
    countryCode: CountryCode.MYS,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Maldives',
    countryCode: CountryCode.MDV,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Mali',
    countryCode: CountryCode.MLI,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Malta',
    countryCode: CountryCode.MLT,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Marshall Islands',
    countryCode: CountryCode.MHL,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Martinique',
    countryCode: CountryCode.MTQ,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Mauritania',
    countryCode: CountryCode.MRT,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Mauritius',
    countryCode: CountryCode.MUS,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Mayotte',
    countryCode: CountryCode.MYT,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Mexico',
    countryCode: CountryCode.MEX,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.es_MX,
  },
  {
    name: 'Micronesia (Federated States of)',
    countryCode: CountryCode.FSM,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Moldova (Republic of)',
    countryCode: CountryCode.MDA,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.en,
  },
  {
    name: 'Monaco',
    countryCode: CountryCode.MCO,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.en,
  },
  {
    name: 'Mongolia',
    countryCode: CountryCode.MNG,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Montenegro',
    countryCode: CountryCode.MNE,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Montserrat',
    countryCode: CountryCode.MSR,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Morocco',
    countryCode: CountryCode.MAR,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'Mozambique',
    countryCode: CountryCode.MOZ,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Myanmar',
    countryCode: CountryCode.MMR,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Namibia',
    countryCode: CountryCode.NAM,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Nauru',
    countryCode: CountryCode.NRU,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Nepal',
    countryCode: CountryCode.NPL,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Netherlands',
    countryCode: CountryCode.NLD,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.nl,
  },
  {
    name: 'New Caledonia',
    countryCode: CountryCode.NCL,
    region: 'Oceania',
    subRegion: 'Melanesia',
    locale: Locale.en,
  },
  {
    name: 'New Zealand',
    countryCode: CountryCode.NZL,
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    locale: Locale.en,
  },
  {
    name: 'Nicaragua',
    countryCode: CountryCode.NIC,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Niger',
    countryCode: CountryCode.NER,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Nigeria',
    countryCode: CountryCode.NGA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Niue',
    countryCode: CountryCode.NIU,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Norfolk Island',
    countryCode: CountryCode.NFK,
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    locale: Locale.en,
  },
  {
    name: 'Northern Mariana Islands',
    countryCode: CountryCode.MNP,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Norway',
    countryCode: CountryCode.NOR,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.no,
  },
  {
    name: 'Oman',
    countryCode: CountryCode.OMN,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Pakistan',
    countryCode: CountryCode.PAK,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Palau',
    countryCode: CountryCode.PLW,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Palestine, State of',
    countryCode: CountryCode.PSE,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Panama',
    countryCode: CountryCode.PAN,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Papua New Guinea',
    countryCode: CountryCode.PNG,
    region: 'Oceania',
    subRegion: 'Melanesia',
    locale: Locale.en,
  },
  {
    name: 'Paraguay',
    countryCode: CountryCode.PRY,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Peru',
    countryCode: CountryCode.PER,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Philippines',
    countryCode: CountryCode.PHL,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Pitcairn',
    countryCode: CountryCode.PCN,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Poland',
    countryCode: CountryCode.POL,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.pl,
  },
  {
    name: 'Portugal',
    countryCode: CountryCode.PRT,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.pt,
  },
  {
    name: 'Puerto Rico',
    countryCode: CountryCode.PRI,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Qatar',
    countryCode: CountryCode.QAT,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Réunion',
    countryCode: CountryCode.REU,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Romania',
    countryCode: CountryCode.ROU,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.ro,
  },
  {
    name: 'Russian Federation',
    countryCode: CountryCode.RUS,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.ru,
  },
  {
    name: 'Rwanda',
    countryCode: CountryCode.RWA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Saint Barthélemy',
    countryCode: CountryCode.BLM,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    countryCode: CountryCode.SHN,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Saint Kitts and Nevis',
    countryCode: CountryCode.KNA,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Saint Lucia',
    countryCode: CountryCode.LCA,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Saint Martin (French part)',
    countryCode: CountryCode.MAF,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: CountryCode.SPM,
    region: 'Americas',
    subRegion: 'Northern America',
    locale: Locale.en,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: CountryCode.VCT,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Samoa',
    countryCode: CountryCode.WSM,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'San Marino',
    countryCode: CountryCode.SMR,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Sao Tome and Principe',
    countryCode: CountryCode.STP,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Saudi Arabia',
    countryCode: CountryCode.SAU,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Senegal',
    countryCode: CountryCode.SEN,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Serbia',
    countryCode: CountryCode.SRB,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Seychelles',
    countryCode: CountryCode.SYC,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Sierra Leone',
    countryCode: CountryCode.SLE,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Singapore',
    countryCode: CountryCode.SGP,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Sint Maarten (Dutch part)',
    countryCode: CountryCode.SXM,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Slovakia',
    countryCode: CountryCode.SVK,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.sk,
  },
  {
    name: 'Slovenia',
    countryCode: CountryCode.SVN,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.en,
  },
  {
    name: 'Solomon Islands',
    countryCode: CountryCode.SLB,
    region: 'Oceania',
    subRegion: 'Melanesia',
    locale: Locale.en,
  },
  {
    name: 'Somalia',
    countryCode: CountryCode.SOM,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'South Africa',
    countryCode: CountryCode.ZAF,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    countryCode: CountryCode.SGS,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'South Sudan',
    countryCode: CountryCode.SSD,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Spain',
    countryCode: CountryCode.ESP,
    region: 'Europe',
    subRegion: 'Southern Europe',
    locale: Locale.es,
  },
  {
    name: 'Sri Lanka',
    countryCode: CountryCode.LKA,
    region: 'Asia',
    subRegion: 'Southern Asia',
    locale: Locale.en,
  },
  {
    name: 'Sudan',
    countryCode: CountryCode.SDN,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'Suriname',
    countryCode: CountryCode.SUR,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Svalbard and Jan Mayen',
    countryCode: CountryCode.SJM,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en,
  },
  {
    name: 'Sweden',
    countryCode: CountryCode.SWE,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.sv,
  },
  {
    name: 'Switzerland',
    countryCode: CountryCode.CHE,
    region: 'Europe',
    subRegion: 'Western Europe',
    locale: Locale.en,
  },
  {
    name: 'Syrian Arab Republic',
    countryCode: CountryCode.SYR,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Taiwan, Province of China',
    countryCode: CountryCode.TWN,
    region: 'Asia',
    subRegion: 'Eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Tajikistan',
    countryCode: CountryCode.TJK,
    region: 'Asia',
    subRegion: 'Central Asia',
    locale: Locale.en,
  },
  {
    name: 'Tanzania, United Republic of',
    countryCode: CountryCode.TZA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Thailand',
    countryCode: CountryCode.THA,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Timor-Leste',
    countryCode: CountryCode.TLS,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.en,
  },
  {
    name: 'Togo',
    countryCode: CountryCode.TGO,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Tokelau',
    countryCode: CountryCode.TKL,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Tonga',
    countryCode: CountryCode.TON,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Trinidad and Tobago',
    countryCode: CountryCode.TTO,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Tunisia',
    countryCode: CountryCode.TUN,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'Turkey',
    countryCode: CountryCode.TUR,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.tr,
  },
  {
    name: 'Turkmenistan',
    countryCode: CountryCode.TKM,
    region: 'Asia',
    subRegion: 'Central Asia',
    locale: Locale.en,
  },
  {
    name: 'Turks and Caicos Islands',
    countryCode: CountryCode.TCA,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Tuvalu',
    countryCode: CountryCode.TUV,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Uganda',
    countryCode: CountryCode.UGA,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Ukraine',
    countryCode: CountryCode.UKR,
    region: 'Europe',
    subRegion: 'Eastern Europe',
    locale: Locale.en,
  },
  {
    name: 'United Arab Emirates',
    countryCode: CountryCode.ARE,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    countryCode: CountryCode.GBR,
    region: 'Europe',
    subRegion: 'Northern Europe',
    locale: Locale.en_GB,
  },
  {
    name: 'United States of America',
    countryCode: CountryCode.USA,
    region: 'Americas',
    subRegion: 'Northern America',
    locale: Locale.en,
  },
  {
    name: 'United States Minor Outlying Islands',
    countryCode: CountryCode.UMI,
    region: 'Oceania',
    subRegion: 'Micronesia',
    locale: Locale.en,
  },
  {
    name: 'Uruguay',
    countryCode: CountryCode.URY,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Uzbekistan',
    countryCode: CountryCode.UZB,
    region: 'Asia',
    subRegion: 'Central Asia',
    locale: Locale.en,
  },
  {
    name: 'Vanuatu',
    countryCode: CountryCode.VUT,
    region: 'Oceania',
    subRegion: 'Melanesia',
    locale: Locale.en,
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    countryCode: CountryCode.VEN,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Viet Nam',
    countryCode: CountryCode.VNM,
    region: 'Asia',
    subRegion: 'South-eastern Asia',
    locale: Locale.vl,
  },
  {
    name: 'Virgin Islands (British)',
    countryCode: CountryCode.VGB,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Virgin Islands (U.S.)',
    countryCode: CountryCode.VIR,
    region: 'Americas',
    subRegion: 'Latin America and the Caribbean',
    locale: Locale.en,
  },
  {
    name: 'Wallis and Futuna',
    countryCode: CountryCode.WLF,
    region: 'Oceania',
    subRegion: 'Polynesia',
    locale: Locale.en,
  },
  {
    name: 'Western Sahara',
    countryCode: CountryCode.ESH,
    region: 'Africa',
    subRegion: 'Northern Africa',
    locale: Locale.en,
  },
  {
    name: 'Yemen',
    countryCode: CountryCode.YEM,
    region: 'Asia',
    subRegion: 'Western Asia',
    locale: Locale.en,
  },
  {
    name: 'Zambia',
    countryCode: CountryCode.ZMB,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
  {
    name: 'Zimbabwe',
    countryCode: CountryCode.ZWE,
    region: 'Africa',
    subRegion: 'Sub-Saharan Africa',
    locale: Locale.en,
  },
];

const countriesMap = new Map<CountryCode, Country>(countries.map((country) => [country.countryCode, country]));
export default countriesMap;
